// pages/appointment.js

import AppLayout from '@/components/Layouts/AppLayout'
import Head from 'next/head'
import React, { useState, useEffect } from 'react'
import InputError from '@/components/InputError'
import { useAuth } from '@/hooks/auth'
import axios from 'axios'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { format } from 'date-fns'
import { useRouter } from 'next/router'
import PhoneInput from 'react-phone-input-2'
import Popup from '@/components/Popup/Popup'
import 'react-phone-input-2/lib/style.css'

function AppointmentComponent({user_id}) {
    const baseuri = process.env.NEXT_PUBLIC_BACKEND_URL
    const { user } = useAuth({ middleware: 'auth' })
    const router = useRouter()
    console.log('user id is ' , user_id);

    // Appointment state
    const [selectedDate, setSelectedDate] = useState(null)
    const [timeSlots, setTimeSlots] = useState([])
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([])

    useEffect(() => {
        if (selectedDate) {
            fetchAvailableSlots(selectedDate)
        }
        console.log(timeSlots)
    }, [selectedDate])

    const fetchAvailableSlots = async date => {
        try {
            const response = await axios.get(
                `${baseuri}/api/get-available-slots/${user_id}/${format(date, 'yyyy-MM-dd')}`
            )
            if (response.data.status === 200) {
                setTimeSlots(response.data.availableSlots)
            } else {
                console.error('Error fetching time slots:', response.data.message)
            }
        } catch (error) {
            console.error('An error occurred while fetching time slots:', error)
        }
    }

    const [activeInput, setActiveInput] = useState(0)

    const handleInput = index => {
        setActiveInput(prev => (prev === index ? 0 : index))
    }
    const handleDateChange = date => {
        setSelectedDate(date)
    }

    const handleTimeSlotChange = slot => {
        setSelectedTimeSlots(prevSelectedSlots =>
            prevSelectedSlots.includes(slot)
                ? prevSelectedSlots.filter(s => s !== slot)
                : [...prevSelectedSlots, slot]
        )
    }

    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [inputField, setInputField] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        preferred_location: '',
        preferred_meeting_app: '',
        message: '',
        status: 'active',
    })

    const inputsHandler = e => {
        e.persist()
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        })
    }

    // Handle form submission
    const allInfoSubmit = async e => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('first_name', inputField.first_name);
        formData.append('last_name', inputField.last_name);
        formData.append('email', inputField.email);
        formData.append('phone', inputField.phone);
        formData.append('address', inputField.preferred_location);
        formData.append('meeting_app', inputField.preferred_meeting_app);
        formData.append('message', inputField.message);
        formData.append('user_id', user_id);
        formData.append('meeting_type', activeInput);

        // Ensure selectedDate is defined and format it correctly
        if (selectedDate) {
            formData.append('date', format(selectedDate, 'yyyy-MM-dd'));
        } else {
            setErrorMessage('Please select a valid date.');
            setLoading(false);
            return;
        }

        formData.append('time_slots[]', selectedTimeSlots);

        try {
            const res = await axios.post(
                `${baseuri}/api/create-appointment`,
                formData
            );

            if (res.data.status === 200) {
                setLoading(false);
                setSuccessMessage('Appointment created successfully');
                // setTimeout(() => router.push('/dashboard'), 2000);
            } else if (res.data.status === 422 && res.data.errors) {
                setLoading(false);
                setErrors(res.data.errors);
            } else {
                setLoading(false);
                setErrorMessage('An error occurred. Please try again.');
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred:', error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };


    const isDateDisabled = date => {
        const today = new Date()
        return date < today.setHours(0, 0, 0, 0)
    }

    const handleNumChange = (phone, name) => {
        setInputField(prev => ({ ...prev, [name]: phone }))
    }
    return (
        <>
            <Head>
                <title>Create Appointment</title>
            </Head>

            <section>
                <div className=" appointment-form-wrapper">
                    <form onSubmit={allInfoSubmit}>
                        <div className="container">
                            <div className="row align-items-center justify-center wrap-appointment" >
                                <div className="col-lg-12">
                                    <div className="form-group-wrapper mt-3 mb-3">
                                        {/* <div
                                            className="form-group-title"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#website"
                                            aria-expanded="false"
                                            aria-controls="website">
                                            <p>Appointment Calendar</p>
                                        </div> */}

                                        <div
                                            className="information-form appointment-form-content collapse show"
                                            id="website">
                                            <div>
                                                <Calendar
                                                    onChange={handleDateChange}
                                                    value={selectedDate}
                                                    tileDisabled={({ date }) => isDateDisabled(date)}
                                                />
                                                {selectedDate && (
                                                    <div className="appointment-time-item">
                                                        <h3>
                                                            {format(selectedDate, 'MMMM dd, yyyy')}:
                                                        </h3>
                                                        <ul className="appointment-time">
                                                            {timeSlots && timeSlots.length > 0 ? (
                                                                timeSlots.map((slot, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className={
                                                                            selectedTimeSlots.includes(slot)
                                                                                ? 'active'
                                                                                : ''
                                                                        }
                                                                        onClick={() => handleTimeSlotChange(slot)}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            value={slot}
                                                                            readOnly
                                                                            checked={selectedTimeSlots.includes(slot)}
                                                                        />
                                                                        {slot}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                ''
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}

                                            </div>
                                            <div className="appointment-time-item">
                                                <h3>Your Information</h3>
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor=""
                                                                className="input-form-label">
                                                                First Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="first_name"
                                                                placeholder="First name"
                                                                onChange={
                                                                    inputsHandler
                                                                }
                                                            />
                                                            <InputError
                                                                message={
                                                                    errors.last_name
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor=""
                                                                className="input-form-label">
                                                                Last Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="last_name"
                                                                placeholder="Last name"
                                                                onChange={
                                                                    inputsHandler
                                                                }
                                                            />
                                                            <InputError
                                                                message={
                                                                    errors.last_name
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor=""
                                                                className="input-form-label">
                                                                Email
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                placeholder="Enter email"
                                                                onChange={
                                                                    inputsHandler
                                                                }
                                                            />
                                                            <InputError
                                                                message={
                                                                    errors.email
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label
                                                                htmlFor=""
                                                                className="input-form-label">
                                                                Phone Number
                                                            </label>
                                                            <PhoneInput
                                                                country={'us'}
                                                                onChange={phone =>
                                                                    handleNumChange(
                                                                        `+${phone}`,
                                                                        'phone',
                                                                    )
                                                                }
                                                                value={`+${inputField.phone.replace(
                                                                    /^\+/,
                                                                    '',
                                                                )}`}
                                                                placeholder="Enter your number"
                                                            />

                                                            <InputError
                                                                message={
                                                                    errors.phone
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mb-3 d-flex gap-2 align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                id="video_conferencing"
                                                                name="video_conferencing"
                                                                checked={
                                                                    activeInput ===
                                                                    1
                                                                }
                                                                readOnly
                                                            />
                                                            <label
                                                                onClick={() =>
                                                                    handleInput(
                                                                        1,
                                                                    )
                                                                }
                                                                htmlFor="video_conferencing"
                                                                className="input-form-label pb-0">
                                                                Video
                                                                Conferencing
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mb-3 d-flex gap-2 align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                id="add_location"
                                                                name="add_location"
                                                                checked={
                                                                    activeInput ===
                                                                    2
                                                                }
                                                                readOnly
                                                            />
                                                            <label
                                                                onClick={() =>
                                                                    handleInput(
                                                                        2,
                                                                    )
                                                                }
                                                                htmlFor="add_location"
                                                                className="input-form-label pb-0">
                                                                Location
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={`input-value-wrapper ${activeInput === 1
                                                            ? 'active'
                                                            : ''
                                                            }`}>
                                                        {activeInput === 1 && (
                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    name="preferred_meeting_app"
                                                                    placeholder="Preferred Meeting App"
                                                                    onChange={
                                                                        inputsHandler
                                                                    }
                                                                />
                                                                <InputError
                                                                    message={
                                                                        errors.preferred_meeting_app
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* <div
                                                        className={`input-value-wrapper ${activeInput === 2
                                                            ? 'active'
                                                            : ''
                                                            }`}>
                                                        {activeInput === 2 && (
                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-3"
                                                                    name="preferred_location"
                                                                    placeholder="Preferred Location"
                                                                    onChange={
                                                                        inputsHandler
                                                                    }
                                                                />
                                                                <InputError
                                                                    message={
                                                                        errors.preferred_location
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div> */}

                                                    <div className="col-md-12">
                                                        <textarea
                                                            name="message"
                                                            className="form-control mb-3"
                                                            cols="30"
                                                            rows="5"
                                                            placeholder="Message"
                                                            onChange={
                                                                inputsHandler
                                                            }></textarea>
                                                        <InputError
                                                            message={
                                                                errors.message
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="custom-btn mt-4"
                                                    disabled={loading}>
                                                    {loading
                                                        ? 'Submitting...'
                                                        : 'Request for Appointment'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {successMessage && (
                        <Popup
                            message={successMessage}
                            type="success"
                            onClose={() => setSuccessMessage('')}
                        />
                    )}
                    {errorMessage && (
                        <Popup
                            message={errorMessage}
                            type="error"
                            onClose={() => setErrorMessage('')}
                        />
                    )}
                </div>
            </section>
        </>
    )
}

export default AppointmentComponent
